import * as React from 'react'
import { graphql } from 'gatsby'
import EntriesPage from '../components/entries'

const ActivitiesPage = ({ data }) => (
  <EntriesPage title="Education" entries={data.allFile.nodes} />
);

export const query = graphql`
{
    allFile(
      filter: {sourceInstanceName: {eq: "activities"}}
      sort: {order: DESC, fields: childrenMdx___frontmatter___title}
    ) {
      nodes {
        childMdx {
          body
          slug
          frontmatter {
            title
          }
        }
      }
    }
  }  
`;

export default ActivitiesPage
